import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import store from '../store';
import { Partner } from '../assets/types';

const useStyles = makeStyles((theme) => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
}));

const getBrandWebsite = (brand: string) => {
    switch (brand) {
        case "chrome":
            return 'https://www.chromeindustries.com/';
        case "sealskinz":
            return 'https://www.sealskinz.de/';
        case "craghoppers":
            return 'https://www.craghoppers.de/';
        case "eagle_creek":
            return 'https://eaglecreek.eu/';
        case "helinox":
            return 'https://helinox.eu/';
        case "ticket_to_the_moon":
            return 'https://www.ticketothemoon.com/';
        default:
            return ''
    }
}

const getBrandTitle = (brand: string) => {
    switch (brand) {
        case "chrome":
            return 'Chrome';
        case "sealskinz":
            return 'Sealskinz';
        case "craghoppers":
            return 'Craghoppers';
        case "eagle_creek":
            return 'Eagle Creek';
        case "helinox":
            return 'Helinox';
        case "ticket_to_the_moon":
            return 'Ticket to the Moon';
        default:
            return ''
    }
}

const getBrandTextFull = (brand: string) => {
    switch (brand) {
        case "chrome":
            return (
                <React.Fragment>
                    <Typography variant="h6" gutterBottom>Ein Bike-Kult will Deutschland erobern</Typography>
                    <Typography variant="body1" paragraph>In den USA ist Chrome seit zwei Jahrzehnten die Marke für urbane Mobilität. Minimalistische, authentische, funktionale und coole Bags für den Dauereinsatz in der Stadt.</Typography>
                    <Typography variant="body1" paragraph>San Francisco/Rotterdam. Mehr als 20 Jahre ist es her, dass sich in Boulder/Colorado ein paar Fahrradkuriere und Skater in einem Kellerraum zusammenfanden, eine alte Juki-Nähmaschine, LKW-Planen sowie reißfestes Nylon organisierten und sich auf dem Schrottplatz ein paar ausgediente Autosicherheitsgurte besorgten. Der Grund? Es gab auf dem Markt keine Kuriertaschen, die ihren Ansprüchen nach Unverwüstlichkeit, Funktionalität, verlässlichem Sitz und lässigem Look erfüllten. Also kreierten sie kurzerhand ihre eigenen Bags, was in der Szene nicht lange unbemerkt blieb... Die Nachfrage stieg und Chrome Industries wurde gegründet.</Typography>
                    <Typography variant="body1" paragraph>Heute zählt die damals entstandene erste Messenger Bag immer noch nahezu unverändert zu den Ikonen in der Kollektion dieses Kult-Labels, dessen eigene Shops sich mit ihren ungewöhnlichen Angeboten und Event-Programmen zu szenigen Community-Treffpunkten in zahlreichen amerikanischen Städten entwickelt haben. Chromes Taschen-Kollektion hat abseits vom Mainstream in der Szene der urbanen Biker, Surfer und Skater ihre Erfolgsgeschichte fortgeschrieben – auch über die USA hinaus. Gerade aus Europa und insbesondere aus Deutschland stieg die Nachfrage in den letzten Jahren rasant. Chrome antwortet jetzt darauf mit dem Aufbau eines Händlernetzes in den wichtigsten europäischen Ländern, das von der 2015 in Rotterdam gegründeten Europa-Niederlassung gesteuert wird.</Typography>
                    <Typography variant="body1" paragraph>Von Beginn an ging es Chrome um die Entwicklung möglichst minimalistischer, funktionaler, robuster und mit viel Liebe zum Detail designter, cool aussehender Lösungen für Menschen, die in ihrem urbanen Umfeld tagtäglich mit Bike oder Board unterwegs sind. Die einen perfekt passenden, verlässlichen Partner brauchen, der alle Moves mitmacht, viel wegstecken und leicht an- und ausgezogen werden kann. Aus dieser Denke heraus entstand das Erkennungszeichen und zugleich Alleinstellungsmerkmal der Marke: der Steckverschluss – das "Metal Buckle" – eines Autosicherheitsgurtes mit dem prägnanten schwarz-roten Greif-Logo. Der mythische Greif, eine Mischung aus Löwe und Adler, ist Symbol für Stärke und Wachsamkeit. Eigenschaften, die auf den Straßen der Stadt nicht unwichtig sind...</Typography>
                    <Typography variant="body1" paragraph>Chrome gestaltet Equipment für die Stadt. Mittlerweile nicht nur Taschen, sondern auch Textilien und Schuhe. Heritage-Produkt bleiben jedoch die Bags in unterschiedlichen Formen und Größen. Für ganz verschiedene Einsatzgebiete in Job, Schule/Uni, Freizeit, Sport, Musik oder Kunst. Die urbane Kultur ist Chromes Spielplatz und bezeichnend für den Marken-Charakter. Chrome will mit seinen Taschen vom Bike zum Büro bis in die Bar begleiten, wendet sich an urbane Helden, Entdecker und Neudenker. An Hardcore-Biker, Fotografen, Künstler, Kreative, Boarder oder Skater, Studenten und bikende Manager. An alle Menschen in Bewegung, die unverwüstliche, wohlüberlegt einfache, ressourcenschonende, kreative und immer noch teilweise in den USA handgefertigte Taschen suchen, die sich ganz flexibel einsetzen lassen. Was keinen erkennbaren Nutzen hat wird weggelassen. Keine Schnörkel, keine unnötigen Features, ehrliche Nostalgie mit einer Verbeugung an die alten Zeiten, als man noch wusste, wie die Dinge einfach funktionieren und sich auf das Wesentliche beschränkte.</Typography>
                </React.Fragment>
            );
        case "sealskinz":
            return (
                <React.Fragment>
                    <Typography variant="h6" gutterBottom>Marktführer</Typography>
                    <Typography variant="body1" paragraph>Vor 17 Jahren haben wir die weltweit erste wasserdichte Socke auf den Markt gebracht. Von diesem Tag an haben wir neue Technologien entwickelt und Stoffe verwendet, die unsere Produkte zur Weltklasse gemacht haben. Man kann sagen, dass wir den Endurance-Zubehörmarkt geschaffen haben. Da die Kategorie gewachsen ist, haben wir den Wettbewerb begrüßt, da er uns dazu bringt, das Beste zu sein, das wir können.</Typography>
                </React.Fragment>
            );
        case "craghoppers":
            return (
                <React.Fragment>
                    <Typography variant="h6" gutterBottom>Unsere Geschichte</Typography>
                    <Typography variant="body1" paragraph>Im Jahr 1965, als Craghoppers auf die Idee kam, die Leidenschaft für Reisen zu einem echten Geschäft zu machen, hatte das Team das Gefühl, sich auf eine aufregende und manchmal gefährliche Reise ins Unbekannte zu begeben. Rückblickend hat sich ihre Ausdauer ausgezahlt und Craghoppers gilt heute als globaler Spezialist für Outdoor- und Abenteuerreisen.</Typography>
                    <Typography variant="body1" paragraph>Die Welt mag sich in den letzten 50 Jahren weiterentwickelt haben, aber unsere heutige Mission ist dieselbe wie vor vielen Jahrzehnten - komfortable und praktische Kleidungsstücke zu schaffen, die unseren Kunden helfen, so lange wie möglich auf der Spur zu bleiben. Und wir werden jedes Jahr ein bisschen besser. Durch die Einführung neuer Designs, die Verwendung der neuesten Technologien und die Kombination des besonderen Innovationsgeistes sind unsere Produkte komfortabler, leichter, wasserdichter, isolierender und mit mehr Sonnenschutz als je zuvor.</Typography>
                    <Typography variant="body1" paragraph>Wir sind heute Weltmarktführer bei Outdoor- und Abenteuerhosen. Einer unserer größten Erfolge ist NosiLife - eine komplette, leichte Abenteuerkollektion, die einen dauerhaften und sicheren Schutz vor beißenden Insekten und den übertragenen Krankheiten bietet. Wir glauben, dass dies einen wichtigen Beitrag zum Reisen mit heißem Klima gemacht hat. Momente, in denen wir gedacht haben: „Das ist es! Hier möchte ich sein“</Typography>
                    <Typography variant="body1" paragraph>Heute haben wir das Privileg, unser Fernweh mit Millionen von Menschen zu teilen - Mitreisenden, die ebenso gern daran interessiert sind, die weitesten Winkel unseres schönen Planeten zu erkunden und ihre Erfahrungen mit anderen zu teilen.</Typography>
                </React.Fragment>
            );
        case "eagle_creek":
            return (
                <React.Fragment>
                    <Typography variant="body1" paragraph>Wir selbst sind Reisende. Eagle Creek ist in den San Jacinto Mountains in Kalifonien gegründet worden, wo Steve und Nona Barker ein kleines Outdoor-Geschäft namens „Mountain People“ leiteten. Im Jahre 1975 steckten sie ihre gesamte Energie in die Herstellung von Rucksäcken. Heute ist Eagle Creek ein Unternehmen, welches sich darauf spezialisiert hat Reisende auszustatten, um ihnen besondere Erfahrungen und großartige Entdeckungen zu ermöglichen.</Typography>
                </React.Fragment>
            );
        case "helinox":
            return (
                <React.Fragment>
                    <Typography variant="h6" gutterBottom>Die intelligente Wahl</Typography>
                    <Typography variant="body1" paragraph>Egal, ob Sie leichte, zuverlässige Ausrüstung für Ihr nächstes Outdoor-Abenteuer suchen - oder ein elegantes Set für Innen- und Außenausstattung - oder einen perfekt getarnten Sitz für den nächsten Jagdausflug - Helinox ist die einzige kluge Wahl. Helinox-Produkte vereinen innovatives Design mit fortschrittlichen Materialien und höchster Liebe zum Detail. Eine nachgewiesene Erfolgsbilanz umweltfreundlicher Produktionstechniken bedeutet, dass Sie die Natur genießen können, wenn Sie wissen, dass die Leute, die Ihre Helinox-Ausrüstung lieben, auch die Natur lieben. Treffen Sie die intelligente Wahl, wählen Sie Helinox.</Typography>
                    <Typography variant="h6" gutterBottom>Sanfte Kraft</Typography>
                    <Typography variant="body1" paragraph>Um Korrosion zu verhindern und das Aussehen zu erhalten, müssen Aluminiumlegierungskomponenten eloxiert werden. Traditionell bestand das Anodisierungsverfahren aus siedenden Legierungskomponenten in einem Sud aus Salpetersäure und Phosphorsäure. Lange bevor "eco" ein Schlagwort in der Branche wurde, versuchte DAC, schädliche Salpetersäure und Phosphorsäure aus dem Anodisierungsprozess zu entfernen. Nach acht Jahren Versuch und Irrtum perfektionierte DAC das „Green Anodising“ -System, mit dem alle Helinox-Produkte behandelt werden. Ein Wasserrecyclingprogramm in der Eloxalanlage reduziert die Umweltauswirkungen weiter. Das Endergebnis sichert die Langlebigkeit der TH72M-Legierungskomponenten und reduziert gleichzeitig die negativen Auswirkungen auf die Umwelt und die Menschen, die Ihre Helinox-Ausrüstung herstellen.</Typography>
                </React.Fragment>
            );
        case "ticket_to_the_moon":
            return (
                <React.Fragment>
                    <Typography variant="body1" paragraph>Unsere Kernkompetenz liegt in der Herstellung von Hängematten und deren Zubehör. Die aus hochwertiger Fallschirmseide fair hergestellten Hängematten sind langlebig, elastisch, atmungsaktiv, schimmelresistent und hautfreundlich nach ÖKO-TEX® Standard 100. Sie kommen in sechs verschiedenen Größen und verschiedensten Farbkombinationen um allen Ansprüchen gerecht zu werden. Die kompakte und reise-freundliche Tasche im TTTM- Design, die auch als Kopfkissen verwendet werden kann, gibt es kostenfrei dazu. Außerdem bieten wir alles an Zubehör und Extras rund um die Hängematte an (Tarps, Mosquito Net 360° und verschieden Aufhäng-Sets etc.). Einige upcycling Lifestyle-Produkte wie der Moonchair, das Beach Blanket, der Pocket Frisbee sowie die ECO-Friendly Bags runden unser Angebot zusätzlich ab.</Typography>
                    <Typography variant="body1" paragraph>Was mit der Entwicklung der Fallschirm-Hängematte 1996 in Bali, Indonesien begann, führte zu einer weltweit aktiven Bewegung mit einem Portfolio an “feel-good” Produkten mit ethisch reinem Gewissen. Alle unsere Produkte sind das Resultat genauester Handarbeit unserer erfahrenen Mitarbeiter in der eigenen Manufaktur in Bali, wo nur hochwertige Materialien verarbeitet werden. Jedes einzelne unserer Produkte wird unter extremen Bedingungen getestet und erst nach einer strengen Qualitätskontrollen versendet. Unsere 10-jährige Herstellergarantie spricht für sich.</Typography>
                </React.Fragment >
            );
        default:
            return (<Typography>This is a media card. You can use this section to describe the content.</Typography>);
    }
}

const getBrandTextShort = (brand: string) => {
    switch (brand) {
        case "chrome":
            return (<Typography variant="body1">In den USA ist Chrome seit zwei Jahrzehnten die Marke für urbane Mobilität. Minimalistische, authentische, funktionale und coole Bags für den ...</Typography>);
        case "sealskinz":
            return (<Typography variant="body1">Vor 17 Jahren haben wir die weltweit erste wasserdichte Socke auf den Markt gebracht. Von diesem Tag an haben wir neue Technologien entwickelt ...</Typography>);
        case "craghoppers":
            return (<Typography variant="body1">Im Jahr 1965, als Craghoppers auf die Idee kam, die Leidenschaft für Reisen zu einem echten Geschäft zu machen, hatte das Team das Gefühl , sich auf eine ...</Typography>);
        case "eagle_creek":
            return (<Typography variant="body1">Wir selbst sind Reisende. Eagle Creek ist in den San Jacinto Mountains in Kalifonien gegründet worden, wo Steve und Nona Barker ein kleines ...</Typography>);
        case "helinox":
            return (<Typography variant="body1">Egal, ob Sie leichte, zuverlässige Ausrüstung für Ihr nächstes Outdoor-Abenteuer suchen - oder ein elegantes Set für Innen- und Außenausstattung ...</Typography>);
        case "ticket_to_the_moon":
            return (<Typography variant="body1">Was mit der Entwicklung der Fallschirm-Hängematte 1996 in Bali, Indonesien begann, führte zu einer weltweit aktiven Bewegung mit einem Portfolio ...</Typography>);
        default:
            return '';
    }
}

export const Portfolio = () => {
    const classes = useStyles();
    const partner: Partner[] = store.getPartner();
    const [activePartner, setActivePartner] = useState(store.getActivePartner);

    const portfolioRef = useRef<HTMLInputElement>(null);
    const executeScroll = () => {
        if (portfolioRef.current) {
            portfolioRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }
    store.setScrollPortfolio(executeScroll)

    return (
        <Container className={classes.cardGrid} maxWidth="md" ref={portfolioRef}>
            <Grid container spacing={4}>
                {partner.map((partner) => (
                    <Grid item key={partner.name} xs={12} sm={activePartner === partner.name ? 12 : 6} md={activePartner === partner.name ? 12 : 4}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={partner.image}
                                title={partner.name}
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h4">
                                    {getBrandTitle(partner.name)}
                                </Typography>
                                {activePartner === partner.name ? getBrandTextFull(partner.name) : getBrandTextShort(partner.name)}
                            </CardContent>
                            <CardActions>
                                <Button size="small" color="primary" onClick={() => setActivePartner(activePartner === partner.name ? null : partner.name)}>
                                    {activePartner === partner.name ? 'Einklappen' : 'Mehr'}
                                </Button>
                                <Button size="small" color="secondary">
                                    <Link color="secondary" href={getBrandWebsite(partner.name)} target="_blank">Zur Website</Link>
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}
