import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: "70vh",
        display: "flex",
        justifyContent: "center",
        paddingTop: theme.spacing(4),
    }
}));

export const Error = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="sm">
            <Typography className={classes.container} variant="body1" paragraph>
                Oops! Page not found!
            </Typography>
        </Container>
    );
}
