import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Company } from './components/Company';
import { Portfolio } from './components/Portfolio';
import { Services } from './components/Services';
import { Imprint } from './components/Imprint';
import { Error } from './components/Error';


const Content = () => {
  return (
    <React.Fragment>
      <Company />
      <Services />
      <Portfolio />
    </React.Fragment>
  );
}

export default function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <Switch>
        <Route exact path="/" component={Content} />
        <Route path="/imprint" component={Imprint} />
        <Route component={Error} />
      </Switch>
      <Footer />
    </React.Fragment>
  );
}