import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';


const useStyles = makeStyles((theme) => ({
    backButton: {
        display: 'block',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
        cursor: 'pointer',
        color: theme.palette.secondary.main
    }
}));

export const Imprint = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Container maxWidth="md" >
                <Link className={classes.backButton} variant="h6" href="/">Zurück</Link>
                <Typography variant="body1" paragraph>Geb. Fuchs Sportbekleidung-Agentur oHG<br />
                Römertstraße 14<br />
                82131 Gauting <br /><br />
                Tel.: +49 89 89 308 381 <br />
                        Fax: +49 89 89 308 382 <br />
                        office@fuchsohg.de <br />
                    <Link href="https://www.agentur-fuchs.de">www.agentur-fuchs.de</Link> <br /><br />

                        Amtsgericht München <br />
                        HRA 65586 <br />
                        Geschäftsführer: Maximilian und Michael Fuchs <br />
                        USt-IdNr.:DE 131379918 <br />
                </Typography>
                <Typography variant="h4" gutterBottom>Allgemeiner Hinweis und Pflichtinformationen</Typography>
                <Typography variant="h6" gutterBottom>Benennung der verantwortlichen Stelle</Typography>
                <Typography variant="body1" paragraph>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br />
                    <br />
                Geb. Fuchs Sportbekleidung-Agentur oHG<br />
                Michael Fuchs<br />
                Römerstraße 14<br />
                82131 Gauting<br />
                    <br />
                Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
                </Typography>
                <Typography variant="h6" gutterBottom>Widerruf Ihrer Einwilligung zur Datenverarbeitung</Typography>
                <Typography variant="body1" paragraph>Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</Typography>
                <Typography variant="h6" gutterBottom>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</Typography>
                <Typography variant="body1" paragraph>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:<br /><br /> <Link href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit</Link></Typography>
                <Typography variant="h6" gutterBottom>Recht auf Datenübertragbarkeit</Typography>
                <Typography variant="body1" paragraph>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</Typography>
                <Typography variant="h6" gutterBottom>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</Typography>
                <Typography variant="body1" paragraph>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</Typography>
                <Typography variant="h6" gutterBottom>Server-Log-Dateien</Typography>
                <Typography variant="body1" paragraph>In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                <br /><br />
                Browsertyp und Browserversion<br />
                Verwendetes Betriebssystem<br />
                Referrer URL<br />
                Hostname des zugreifenden Rechners<br />
                Uhrzeit der Serveranfrage<br />
                IP-Adresse<br />
                    <br />
                Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                <br />
                Quelle: Datenschutz-Konfigurator von mein-datenschutzbeauftragter.de
                </Typography>
            </Container>
        </React.Fragment>
    );
}
