import SealskinzImage from './images/image_sealskinz.jpg';
import ChromeImage from './images/image_chrome.jpg';
import CraghoppersImage from './images/image_craghoppers.jpg';
import EagleCreekImage from './images/image_eagle_creek.jpg';
import HelinoxImage from './images/image_helinox.jpg';
import TicketToTheMoonImage from './images/image_ticket_to_the_moon.jpg';

import { Partner } from './assets/types';
import { Service } from './assets/types';

const createStore = () => {

    const partner: Partner[] = [
        { name: 'chrome', text: 'This is a media card. You can use this section to describe the content.', image: ChromeImage },
        { name: 'sealskinz', text: 'This is a media card. You can use this section to describe the content.', image: SealskinzImage },
        { name: 'craghoppers', text: 'This is a media card. You can use this section to describe the content.', image: CraghoppersImage },
        { name: 'eagle_creek', text: 'This is a media card. You can use this section to describe the content.', image: EagleCreekImage },
        { name: 'helinox', text: 'This is a media card. You can use this section to describe the content.', image: HelinoxImage },
        { name: 'ticket_to_the_moon', text: 'This is a media card. You can use this section to describe the content.', image: TicketToTheMoonImage },
    ]

    const services_producer: Service[] = [{ name: 'competitor', text: 'Mitbewerber Beobachtung' }, { name: 'market', text: 'Marktentwicklung' }, { name: 'training', text: 'Produkt Schulung und Training' }]
    const services_retailer: Service[] = [{ name: 'sale', text: 'Verkauf und Nachorder' }, { name: 'events', text: 'Messen und Events' }, { name: 'communication', text: 'Kommunikation UPC, Preise und Bilder' }]

    var activePartner: string | null = null;
    var scrollPortfolio: (() => void) = () => null;
    var scrollServices: (() => void) = () => null;
    var subscribers: (() => void)[] = [];

    return {
        subscribe: (fn: (() => void)) => { subscribers.push(fn) },
        scrollToPortfolio: () => { scrollPortfolio() },
        scrollToServices: () => { scrollServices() },
        getActivePartner: () => activePartner,
        setActivePartner: (partner: string | null) => {
            activePartner = partner;
            subscribers.forEach(fn => fn())
        },
        getPartner: () => partner,
        getServices: () => ([services_producer, services_retailer]),
        setScrollPortfolio: (fn: (() => void)) => scrollPortfolio = fn,
        setScrollServices: (fn: (() => void)) => scrollServices = fn
    }
}

const store = createStore()

export default store