import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';

import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(6),
        color: "#8C8C88",
        display: 'flex',
        flexDirection: 'column'
    },
    footerIcon: {
        marginRight: theme.spacing(1),
        marginLeft: 'auto',
        display: 'inline-block',
        float: 'left',
        marginTop: theme.spacing(0),
        color: "#8C8C88",
    },
    footerServiceLine: {
        display: 'flex',
    },
    footerServiceText: {
        marginRight: 'auto'
    },
    link: {
        color: theme.palette.info.main,
        margin: 'auto',
    },
    title: {
        color: theme.palette.secondary.main
    }
}));

function Copyright() {
    return (
        <Typography variant="body2" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.agentur-fuchs.de">
                Sportmode Agentur Fuchs
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export const Footer = () => {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <Typography className={classes.title} variant="h6" align="center" gutterBottom>
                Kontakt
            </Typography>
            <Typography variant="subtitle1" align="center" component="div" className={classes.footerServiceLine}>
                <MailOutlineOutlinedIcon className={classes.footerIcon} />
                <Typography className={classes.footerServiceText}>office@fuchsohg.de</Typography>
            </Typography>
            <Typography variant="subtitle1" align="center" component="div" className={classes.footerServiceLine}>
                <CallOutlinedIcon className={classes.footerIcon} />
                <Typography className={classes.footerServiceText}>+49 89 324 40 42</Typography>
            </Typography>
            <Typography variant="subtitle1" align="center" component="div" className={classes.footerServiceLine}>
                <BusinessOutlinedIcon className={classes.footerIcon} />
                <Typography className={classes.footerServiceText}>Lilienthalallee 40, 80939 München (MOC - A119)</Typography>
            </Typography>
            <Link className={classes.link} href="/imprint">
                Impressum und Datenschutz
            </Link>
            <Copyright />
        </footer>
    )
}
