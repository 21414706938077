import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Logo from '../images/agenturfuchs_white.png'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            display: 'flex',
            color: 'inherit',
            cursor: 'pointer',
            '&:hover': { textDecoration: 'none' }
        },
        logo: {
            width: theme.spacing(7),
            height: theme.spacing(4.7),
            marginRight: theme.spacing(2),
        },
        title: {
            lineHeight: theme.spacing(0.25)
        }
    }),
);
export const Header = () => {

    const classes = useStyles();

    return (
        <AppBar position="relative">
            <Toolbar>
                <Link className={classes.link} href="/">
                    <img src={Logo} className={classes.logo} alt="Logo Agentur Fuchs" />
                    <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                        Sportmode Agentur Fuchs
                </Typography>
                </Link>
            </Toolbar>
        </AppBar>
    )
}
