import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import StoreIcon from '@material-ui/icons/Store';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import SchoolIcon from '@material-ui/icons/School';

import store from '../store';

const service_text1 = "Von unseren Dienstleistungen profitieren sowohl Händler, als auch Hersteller."

const useStyles = makeStyles((theme) => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        boxShadow: '0 0 0 0',
    },
    cardIcon: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
    },
    cardContent: {
        flexGrow: 1,
    },
    serviceText1: {
        marginBottom: theme.spacing(8),
        maxWidth: '50%',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    serviceText2: {
        marginTop: theme.spacing(8),
        maxWidth: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(0)
    },
    serviceIcon: {
        // fontSize: 1
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        fontSize: 'xx-large',
        display: 'inline-block',
        float: 'left',
        marginTop: theme.spacing(-0.5),
        color: "#8C8C88",
    },
    serviceItem: {
        margin: theme.spacing(2)
    },
    serviceArrow: {
        display: 'block',
        float: 'left'
    }
}));

const getServiceIcon = (service: string, classes: Record<string, string>) => {
    switch (service) {
        case 'sale':
            return <ShoppingCartIcon className={classes.serviceIcon} />;
        case 'competitor':
            return <StoreIcon className={classes.serviceIcon} />;
        case 'market':
            return <TimelineOutlinedIcon className={classes.serviceIcon} />;
        case 'events':
            return <EventAvailableIcon className={classes.serviceIcon} />;
        case 'communication':
            return <MonetizationOnIcon className={classes.serviceIcon} />;
        case 'training':
            return <SchoolIcon className={classes.serviceIcon} />
        default:
            return null;
    }
}

export const Services = () => {
    const classes = useStyles();
    const services = store.getServices();

    const servicesRef = useRef<HTMLInputElement>(null);
    const executeScroll = () => {
        if (servicesRef.current) {
            servicesRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }
    store.setScrollServices(executeScroll);

    return (
        <Container className={classes.cardGrid} maxWidth="md" ref={servicesRef}>
            <Typography className={classes.serviceText1} variant="h5" align="center" color="textSecondary" paragraph>
                {service_text1}
            </Typography>
            <Grid container spacing={4}>
                {services.map((service_items, index) => (
                    <Grid item key={index} xs={12} sm={6} md={6}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                {service_items.map((item) => (
                                    <div key={item.name} className={classes.serviceItem}>
                                        {getServiceIcon(item.name, classes)}
                                        <Typography>
                                            {item.text}
                                        </Typography>
                                    </div>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}
