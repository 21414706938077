import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';

import store from '../store';

const company_desc = "Wir sind Vertriebspartner führender Premium Marken der Sport- und Outdoor Szene. Zu unserem Kundenkreis zählen wir ausgewählte Fachhandelsgeschäfte in ganz Deutschland."

const useStyles = makeStyles((theme) => ({
    buttonDark: {
        '&:hover': { backgroundColor: theme.palette.secondary.main },
    },
    buttonLight: {
        color: theme.palette.secondary.main
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
}));

export const Company = () => {
    const classes = useStyles();

    return (
        <div className={classes.heroContent}>
            <Container maxWidth="sm">
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Agentur Fuchs
                </Typography>
                <Typography variant="h5" align="center" color="textSecondary" paragraph>
                    {company_desc}
                </Typography>
                <div className={classes.heroButtons}>
                    <Grid container spacing={2} justify="center">
                        <Grid item>
                            <Button variant="contained" color="primary" className={classes.buttonDark} onClick={() => store.scrollToPortfolio()}>
                                Unsere Partner
                  </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" color="primary" className={classes.buttonLight} onClick={() => store.scrollToServices()}>
                                Services
                  </Button>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    )
}
